import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import PodcastListing from '../components/PodcastListing';

class PodcastsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges').filter(
      p => p.node.frontmatter.template === 'podcast'
    )
    console.log(this.props.data.allMarkdownRemark.edges)

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <h1>Podcasts</h1>
          {posts.length > 0 ? posts.map(({ node }) => (
            <PodcastListing node={node} key={node.id} />
          )) : <p>Podcasts coming soon.</p>}
      </Layout>
    )
  }
}

export default PodcastsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(format: HTML)
          fields {
            tagSlugs
            slug
          }
          frontmatter {
            template
            date(formatString: "MMMM DD, YYYY")
            tags
            author
            title
          }
        }
      }
    }
  }
`
